/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const createAccount = /* GraphQL */ `
  mutation CreateAccount($input: CreateAccountInput!) {
    createAccount(input: $input) {
      id
      label
      subtype
      status
      config {
        external
        scopes
        grant_types
        response_types
        redirect_uris
        login_uri
        code_challenge_methods
        signed_request
        jwks_uri
        authentication {
          method
          factor
          output
        }
        tokens {
          access {
            exp
            use
            aud
            ext
            jwk
          }
          identity {
            exp
            use
            ext
            jwk
          }
          refresh {
            exp
            use
          }
          code {
            exp
            use
          }
        }
      }
      invite_token
    }
  }
`
export const createFactor = /* GraphQL */ `
  mutation CreateFactor($input: CreateFactorInput!) {
    createFactor(input: $input) {
      id
      label
      subtype
      score
      config {
        unique
        case_sensitive
        public_signup
        require_validation_for_enablement
        max_attempts
        response_type
        issuer
        client_id
        client_secret
        client_keys {
          public
          private
        }
        client_authentication
        signed_request
        authorization_endpoint
        response_mode
        scope
        jwks_uri
        content_type
        token_endpoint
        regex
        otp
        nonce
        nonce_regex
        code_challenge_method
        code_challenge_regex
        window
        auto_unlock
        capture_input
        capture_claims
        capture_tokens
      }
    }
  }
`
export const createTenant = /* GraphQL */ `
  mutation CreateTenant($input: CreateTenantInput!) {
    createTenant(input: $input) {
      id
      label
      subscription
      account
      config {
        color
        signup_session_expiration
        login_session_expiration
        inactive_account_expiration
        inactive_tenant_expiration
        hlp {
          background_color
          background_text_color
          background_link_color
          widget_color
          widget_text_color
          widget_link_color
          logo
        }
      }
    }
  }
`
export const createToken = /* GraphQL */ `
  mutation CreateToken($input: CreateTokenInput!) {
    createToken(input: $input) {
      id
      label
      type
      subtype
      tenant
      account
    }
  }
`
export const createRule = /* GraphQL */ `
  mutation CreateRule($input: CreateRuleInput!) {
    createRule(input: $input) {
      id
      label
      account
      control
      required
      status
      created_at
      created_by
      updated_at
      updated_by
      expires_at
    }
  }
`
export const createExtension = /* GraphQL */ `
  mutation CreateExtension($input: CreateExtensionInput!) {
    createExtension(input: $input) {
      label
      config {
        code
        rule {
          type
          origin
          action
          result
          reason
          account
        }
      }
    }
  }
`
export const createControl = /* GraphQL */ `
  mutation CreateControl($input: CreateControlInput!) {
    createControl(input: $input) {
      id
      label
      config {
        consent_required
        permission_required
      }
      score
      status
      subtype
      value
    }
  }
`
export const createConsent = /* GraphQL */ `
  mutation CreateConsent($input: CreateConsentInput!) {
    createConsent(input: $input) {
      id
      label
      status
      control
      choice
      recipient
      account
    }
  }
`
export const updateConsent = /* GraphQL */ `
  mutation UpdateConsent($input: UpdateConsentInput!) {
    updateConsent(input: $input) {
      id
      label
      status
      control
      choice
      recipient
      account
    }
  }
`
export const createPermission = /* GraphQL */ `
  mutation CreatePermission($input: CreatePermissionInput!) {
    createPermission(input: $input) {
      id
      label
      status
      control
      account
    }
  }
`
export const updatePermission = /* GraphQL */ `
  mutation UpdatePermission($input: UpdatePermissionInput!) {
    updatePermission(input: $input) {
      id
      label
      status
      control
      recipient
    }
  }
`
export const deleteConsent = /* GraphQL */ `
  mutation DeleteConsent($input: DeleteConsentInput!) {
    deleteConsent(input: $input) {
      id
    }
  }
`
export const deleteRule = /* GraphQL */ `
  mutation deleteRule($input: DeleteRuleInput!) {
    deleteRule(input: $input) {
      id
    }
  }
`
export const deleteControl = /* GraphQL */ `
  mutation DeleteControl($input: DeleteControlInput!) {
    deleteControl(input: $input) {
      id
    }
  }
`
export const deletePermission = /* GraphQL */ `
  mutation DeletePermission($input: DeletePermissionInput!) {
    deletePermission(input: $input) {
      id
    }
  }
`
export const deleteAccount = /* GraphQL */ `
  mutation DeleteAccount($input: DeleteAccountInput!) {
    deleteAccount(input: $input) {
      id
    }
  }
`
export const deleteFactor = /* GraphQL */ `
  mutation DeleteFactor($input: DeleteFactorInput!) {
    deleteFactor(input: $input) {
      id
    }
  }
`
export const deleteEnrollment = /* GraphQL */ `
  mutation DeleteEnrollment($input: DeleteEnrollmentInput!) {
    deleteEnrollment(input: $input) {
      id
    }
  }
`
export const deleteTenant = /* GraphQL */ `
  mutation DeleteTenant($input: DeleteTenantInput!) {
    deleteTenant(input: $input) {
      id
    }
  }
`
export const deleteExtension = /* GraphQL */ `
  mutation DeleteExtension($input: DeleteExtensionInput!) {
    deleteExtension(input: $input) {
      id
    }
  }
`
export const deleteToken = /* GraphQL */ `
  mutation DeleteToken($input: DeleteTokenInput!) {
    deleteToken(input: $input) {
      id
    }
  }
`
export const updateExtension = /* GraphQL */ `
  mutation UpdateExtension($input: UpdateExtensionInput!) {
    updateExtension(input: $input) {
      id
      label
      status
    }
  }
`
export const updateControl = /* GraphQL */ `
  mutation UpdateControl($input: UpdateControlInput!) {
    updateControl(input: $input) {
      id
      label
      config {
        consent_required
        permission_required
      }
      score
      value
      subtype
      status
    }
  }
`
export const updateRule = /* GraphQL */ `
  mutation UpdateRule($input: UpdateRuleInput!) {
    updateRule(input: $input) {
      id
      label
      status
      required
      control
      account
      created_at
      created_by
      updated_at
      updated_by
      expires_at
    }
  }
`
export const updateAccount = /* GraphQL */ `
  mutation UpdateAccount($input: UpdateAccountInput!) {
    updateAccount(input: $input) {
      id
      label
      subtype
      status
      config {
        external
        scopes
        grant_types
        response_types
        redirect_uris
        login_uri
        code_challenge_methods
        signed_request
        jwks_uri
        authentication {
          method
          factor
          output
        }
        tokens {
          access {
            exp
            use
            aud
            ext
            jwk
          }
          identity {
            exp
            use
            ext
            jwk
          }
          refresh {
            exp
            use
          }
          code {
            exp
            use
          }
        }
      }
    }
  }
`
export const updateFactor = /* GraphQL */ `
  mutation UpdateFactor($input: UpdateFactorInput!) {
    updateFactor(input: $input) {
      id
      label
      status
      score
      config {
        case_sensitive
        public_signup
        unique
        require_validation_for_enablement
        max_attempts
        response_type
        issuer
        client_id
        client_secret
        client_keys {
          public
          private
        }
        client_authentication
        signed_request
        authorization_endpoint
        response_mode
        scope
        jwks_uri
        content_type
        token_endpoint
        regex
        code_challenge_method
        code_challenge_regex
        nonce
        nonce_regex
        window
        auto_unlock
        capture_input
        capture_claims
        capture_tokens
      }
    }
  }
`
export const updateEnrollment = /* GraphQL */ `
  mutation UpdateEnrollment($input: UpdateEnrollmentInput!) {
    updateEnrollment(input: $input) {
      id
      label
      status
    }
  }
`
export const updateTenant = /* GraphQL */ `
  mutation UpdateTenant($input: UpdateTenantInput!) {
    updateTenant(input: $input) {
      id
      label
      status
      subscription
      config {
        color
        tokens {
          login {
            exp
          }
          signup {
            exp
          }
        }
        accounts {
          pending {
            exp
          }
          enabled {
            exp
          }
        }
        hlp {
          background_color
          background_text_color
          background_link_color
          widget_color
          widget_text_color
          widget_link_color
          logo
        }
      }
    }
  }
`
export const updateToken = /* GraphQL */ `
  mutation UpdateToken($input: UpdateTokenInput!) {
    updateToken(input: $input) {
      id
      label
      status
    }
  }
`
